.gender-bias-highlight {
    z-index: 1;
    border-radius: 3px;
    text-align: center;
}

.gender-bias-highlight-tooltip {
    background-color: rgba(0, 0, 0, 1);
    border-radius: 3px;
    text-align: center;
}

.gender-bias-check {
    
    padding: 3px;
    border-radius: 3px;
}


.gender-bias-icon {
    border-radius: 5px;
    height: 50px;
}

.gender-bias-text {
    float: left;
    padding: 3px;
    color: black;
    padding: "0px 10px 0px 10px";
    
}

.gender-bias-show-details {
    float: left;
    vertical-align: middle;
    text-decoration: none;
    margin-top: 1.5px;
    color: rgb(133, 131, 131);
    font-weight: 5px;
    
}

.gender-bias-show-details-button {
    /* float: left; */
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    margin-top: 1.5px;
    color: rgb(133, 131, 131);
    font-weight: 5px;
    margin: 0 100px 0 100px;
    /* padding-right: 10px; */
    
}

.gender-bias-details-popup{
    width: 250px;
    height: 300px;
    position: relative;
    z-index: 3;
    border-radius: 3px;
    border-color: white;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: auto;
}
.gender-bias-details-summary{
    padding: 10px;
    margin: 5px;
    font-size: 0.9rem;
    border-radius: 3px;
    border-color: lightgray;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
}
.gender-bias-details-bar{
    padding: 10px;
}
.gender-bias-details-words{
    padding: 10px;
    text-align: left;
    margin: 5px;
    font-size: 0.9rem;
    border-radius: 3px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    
}
.gender-bias-details-header {
    vertical-align: middle;
    height: 30px;
    padding-top: 3px;
    text-align: center;
    border-radius: 3px;
    border-color: lightgray;
    border-style: solid;
    border-width: 0px 0px 3px 0px;
    margin-top: 5px;
  
}
.gender-bias-details-popup-close{
    float: right;
    padding: 5px;
    text-decoration: none;
}
.gender-bias-details-bar-male-count{
    float:right;
}